import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import moment from "moment-timezone";
import { Modal, Button } from "antd";
import { Switch } from "antd";

const ReadyReport = () => {
  const { loginData } = useSelector((store) => store.auth);
  const { readyReport, assignedAgent } = useSelector((store) => store.cases);

  const [search, setSearch] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [filteredCase, setFilteredCase] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [urlPath, setUrlPath] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCase, setSelectedCase] = useState('');
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [selectedDateCase, setSelectedDateCase] = useState('');
  const [alterDate, setAlterDate] = useState(new Date());
  const [alterUpdateDate, setAlterUpdateDate] = useState(new Date());

  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [PaymentCase, setPaymentCase] = useState('');
  const [statusOfPayment, setStatusOfPayment] = useState(null)
  const [remarkOfPayment, setRemarkOfPayment] = useState('')

  const showPaymentModal = (data) => {
    setPaymentCase(data)
    setStatusOfPayment(data?.paymentStatus)
    setRemarkOfPayment(data?.paymentRemark)
    setPaymentModalOpen(true)
  }
  const handlePaymentStatus = () => {
    setStatusOfPayment(!statusOfPayment)
  }
  const handlePaymentCancel = () => {
    setPaymentModalOpen(false);
  };
  const handleUpdatePayment = async (e) => {
    const updateCase = {
      ...PaymentCase,
      paymentStatus: statusOfPayment,
      paymentRemark: remarkOfPayment
    }
    console.log(updateCase,'updateCase');
    const config = {
      Headers: { "Content-Type": "application/json" },
    };
    const url = `${Baseurl}/api/v1/cases/updatecase/${PaymentCase._id}`;
    const resp = await axios.put(url, updateCase, config);
    if (resp.data.success) {
      setPaymentCase('')
      setStatusOfPayment(null)
      setPaymentModalOpen(false)
      alert('Payment Status updated successfully')
      window.location.reload()
    }
  }

  const showDateModal = (data) => {
    setIsDateModalOpen(true);
    setSelectedDateCase(data);
  };

  const handleDateOk = () => {
    setIsDateModalOpen(false);
  };

  const handleDateCancel = () => {
    setIsDateModalOpen(false);
  };

  const updateDateClick = async (e) => {
    const selectedDate = new Date(alterDate);
    const selectedUpdateDate = new Date(alterUpdateDate);
    const currentDate = new Date();
    if (selectedDate < currentDate && selectedUpdateDate < currentDate) {
      const formData = {
        createdAt: alterDate,
        submittedAt: alterUpdateDate
      }
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/cases/updatecase/${selectedDateCase._id}`;
      const resp = await axios.put(url, formData, config);
      if (resp.data.success) {
        setIsDateModalOpen(false);
        setSelectedDateCase('')
        alert('Date updated successfully')
      }
      //   window.location.reload()
    } else {
      alert('Please select a valid date')
      setIsDateModalOpen(false);
      setSelectedDateCase('')
    }
  }
  const onChange = (e) => {
    const selectedDate = new Date(e.target.value);
    const currentDate = new Date();
    if (selectedDate > currentDate) {
      alert("Selected date is in the future. Please select a valid date.");
    } else {
      if (selectedDate) {
        const formattedDate = selectedDate.toISOString()
        setAlterDate(formattedDate);
      }
    }
  };
  const onUpdateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    const currentDate = new Date();
    if (selectedDate > currentDate) {
      alert("Selected date is in the future. Please select a valid date.");
    } else {
      if (selectedDate) {
        const formattedDate = selectedDate.toISOString()
        setAlterUpdateDate(formattedDate);
      }
    }
  };

  const showModal = (data) => {
    setSelectedCase(data)
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const updateClick = async () => {
    const url = `${Baseurl}/api/v1/cases/deletecase/${selectedCase._id}`
    const resp = await axios.delete(url)
    console.log(resp.data);
    if (resp.data.success) {
      setIsModalOpen(false);
      setSelectedCase('')
      window.location.reload()
      // alert(`Case of ${selectedCase.requesterName} deleted succesfully`)
    }
  }
  useEffect(() => {
    const getAllReports = readyReport
    if (loginData?.role === 'businessAssociate') {
      const data = getAllReports.filter((indCase) =>
        loginData.permittedState.includes(indCase.state) && indCase.businessAssociate?.id === loginData._id
      )
      setFilterData(data)
      setFilteredCase(data)
    } else {
      setFilterData(getAllReports)
      setFilteredCase(getAllReports)
    }
  }, [readyReport])


  const handleSearch = (e) => {
    if (search.trim() !== '') {
      const filterBySearch = filterData.filter((indData) =>
        indData.regdNo.toUpperCase() === search.trim().toUpperCase()
      )
      setFilterData(filterBySearch)
    } else {
      alert('enter vehicle no.')
    }
  }

  const handleReset = () => {
    setFilterData(filteredCase)
    setSearch('')
  }


  const handleUpdateReport = async (individualCase) => {
    const formData = {
      status: 4,
      statusText: "Case Submitted",
    }
    const config = {
      Headers: { "Content-Type": "application/json" },
    };
    const url = `${Baseurl}/api/v1/cases/updatecase/${individualCase._id}`;
    const resp = await axios.put(url, formData, config);
    if (resp.data.success) {
      console.log("hiii");
      navigate(`/admin/editreport/${individualCase._id}`);
    }
  }

  const handleReport = (admin) => {
    const assetType = admin.assetType;
    if (assetType === 'Four Wheeler' || assetType === 'Car Taxi' || assetType === 'Motor Cab') {
      navigate(`/releasereport/${admin._id}`)
    } else if (assetType === 'Two Wheeler') {
      navigate(`/twowheelerreportrelease/${admin._id}`)
    } else if (assetType === 'Construction Equipment') {
      navigate(`/constructionreportrelease/${admin._id}`)
    } else if (assetType === 'Commercial') {
      navigate(`/commercialreportrelease/${admin._id}`)
    } else if (assetType === 'Three Wheeler') {
      navigate(`/threewheelerreportrelease/${admin._id}`)
    } else if (assetType === 'Farm Equipment') {
      navigate(`/firmequipmentreportrelease/${admin._id}`)
    }
  }

  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        <div className="p-2 space-y-6">
          <div className="flex items-center justify-between w-full print:hidden">
            <h4 className="text-lg font-semibold text-default-900">
              Dashboard
            </h4>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '10px' }}>
              <input type="text" placeholder="Enter vehicle no. " value={search} onChange={(e) => setSearch(e.target.value)} style={{ border: '1px solid #dd5536', borderRadius: '10px' }} />
              <button onClick={() => handleSearch()} className="text-white px-4 py" style={{ padding: '10px 15px', backgroundColor: '#e6632e', borderRadius: '10px' }}>Search </button>
              <button onClick={() => handleReset()} className="text-white px-4 py" style={{ padding: '10px 15px', backgroundColor: '#E20808', borderRadius: '10px' }}>Reset </button>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1" style={{ marginBottom: '60px' }}>
          <div className="shadow rounded-lg bg-white dark:bg-default-50">
            <div className="border-t border-dashed border-default-200">
              <div className="relative overflow-x-auto">
                <table className="min-w-full overflow-x-hidden">
                  <thead className="border-b border-dashed border-default-200">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-16"
                      >
                        No
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-40"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Mobile
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        State
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Reg No.
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Report Requested By
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Vehicle Preview
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Signature
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Payment Status
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Payment Update
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Action
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Action
                      </th>
                      {
                        loginData?.role !== 'businessAssociate' &&
                        <th
                          scope="col"
                          className="px-3 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                        >
                          Action
                        </th>
                      }
                      {
                        loginData?.role !== 'businessAssociate' &&
                        <th
                          scope="col"
                          className="px-3 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"

                        >
                          Action
                        </th>
                      }
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-dashed divide-default-200">
                    {filterData &&
                      filterData.map((individualReport, index) => (
                        <tr key={index}>
                          <td className="px-6 py-3 text-center text-default-600 font-semibold whitespace-nowrap"
                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                          >
                            <b>{individualReport.caseId}</b>
                          </td>
                          <td className="px-6 py-3 text-center whitespace-nowrap"
                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                          >
                            <h6 className="text-sm font-semibold text-default-700">
                              {individualReport.name.length > 30 ? (
                                <>{individualReport.name.slice(0, 30)}...</>
                              ) : (
                                <>{individualReport.name.slice(0, 30)}</>
                              )}
                            </h6>
                          </td>
                          <td className="px-6 py-3 text-center text-default-600 font-medium whitespace-nowrap"
                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                          >
                            {individualReport.mobile}
                          </td>
                          <td className="px-6 py-3 text-center text-default-600 font-medium whitespace-nowrap"
                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                          >
                            {moment(individualReport.createdAt)
                              .tz("Asia/Kolkata")
                              .format("YYYY-MM-DD HH:mm:ss")}
                          </td>
                          <td className="px-6 py-3 text-center text-default-600 font-medium whitespace-nowrap"
                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                          >
                            {individualReport.email.length > 18 ? (
                              <>{individualReport.email.slice(0, 18)}...</>
                            ) : (
                              <>{individualReport.email.slice(0, 18)}</>
                            )}
                          </td>
                          <td className="px-6 py-3 text-center text-default-600 font-medium whitespace-nowrap"
                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                          >
                            {individualReport.state}
                          </td>
                          <td className="px-6 py-3 text-center text-default-600 font-medium whitespace-nowrap"
                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                          >
                            {individualReport.regdNo}
                          </td>
                          <td className="px-6 py-3 text-center text-default-600 font-medium whitespace-nowrap"
                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                          >
                            <div className="d-flex m-auto">
                              {individualReport.requesterName}
                            </div>
                          </td>
                          <td className="px-6 py-3 text-default-600 font-medium whitespace-nowrap"
                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                          >
                            <div className="d-flex m-auto">
                              <img
                                src={individualReport.images.frontImage}
                                style={{ height: "40px", width: "60px" }}
                                alt=""
                              />
                            </div>
                          </td>
                          <td className="px-6 py-3 text-default-600 font-medium whitespace-nowrap"
                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                          >
                            <div className="d-flex m-auto">
                              <img
                                src={individualReport.signNdoc.sign}
                                style={{ height: "40px", width: "60px" }}
                                alt=""
                              />
                            </div>
                          </td>
                          <td className="whitespace-nowrap py-3 px-3 text-center text-sm font-medium"
                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                          >
                            <div className="flex items-center justify-center gap-2">
                              <Switch checked={individualReport?.paymentStatus ? true : false} />
                            </div>
                          </td>
                          <td className="whitespace-nowrap py-3 px-3 text-center text-sm font-medium"
                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                          >
                            <div className="flex items-center justify-center gap-2">
                              <button className="inline-flex items-center justify-center p-3 rounded-md bg-cyan-500/20 text-cyan-500 transition-all duration-200 hover:bg-cyan-500 hover:text-white"
                                onClick={() => showPaymentModal(individualReport)}
                              >
                                Update
                              </button>
                            </div>
                          </td>
                          {
                            loginData?.role !== 'businessAssociate' &&
                            <td className="whitespace-nowrap py-3 px-3 text-center text-sm font-medium"
                              style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                            >
                              <div className="flex items-center justify-center gap-2">
                                <button className="inline-flex items-center justify-center p-3 rounded-md bg-cyan-500/20 text-cyan-500 transition-all duration-200 hover:bg-cyan-500 hover:text-white"
                                  onClick={() => showDateModal(individualReport)}
                                >
                                  Alter
                                </button>
                              </div>
                            </td>
                          }
                          <td className="whitespace-nowrap py-3 px-3 text-center text-sm font-medium"
                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                          >
                            <div className="flex items-center justify-center gap-2">
                              <button className="inline-flex items-center justify-center p-3 rounded-md bg-cyan-500/20 text-cyan-500 transition-all duration-200 hover:bg-cyan-500 hover:text-white"
                                onClick={() => handleUpdateReport(individualReport)}
                              >

                                Edit Report

                              </button>
                            </div>
                          </td>
                          <td className="whitespace-nowrap py-3 px-3 text-center text-sm font-medium"
                            style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                          >
                            <div className="flex items-center justify-center gap-2">
                              <button className="inline-flex items-center justify-center p-3 rounded-md bg-cyan-500/20 text-cyan-500 transition-all duration-200 hover:bg-cyan-500 hover:text-white"
                                onClick={() => handleReport(individualReport)}
                              >
                                Generate Report
                              </button>
                            </div>
                          </td>
                          {
                            loginData?.role !== 'businessAssociate' &&
                            <td className="whitespace-nowrap py-3 px-3 text-center text-sm font-medium"
                              style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fff' }}
                            >
                              <div className="flex items-center justify-center gap-2">
                                <button className="inline-flex items-center justify-center p-3 rounded-md bg-cyan-500/20 text-cyan-500 transition-all duration-200 hover:bg-cyan-500 hover:text-white"
                                  onClick={() => showModal(individualReport)}
                                >
                                  Delete
                                </button>
                              </div>
                            </td>
                          }
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="flex flex-wrap items-center justify-between gap-6 py-3 px-6 border-t border-dashed border-default-200">
            </div>
          </div>
        </div>
        <Modal title="Delete Case"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button
              style={{
                padding: "10px 15px",
                color: "#fff",
                backgroundColor: "#FF0000",
              }}
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>,
            <Button
              style={{
                padding: "10px 15px",
                color: "#fff",
                backgroundColor: "#04681e",
              }}
              onClick={(e) => updateClick(e)}
            >
              Confirm
            </Button>,
          ]}
        >
          <p>Are you sure you want to delete case of {selectedCase.requesterName}</p>
        </Modal>
        <Modal title="AlterDate"
          open={isDateModalOpen}
          onOk={handleDateOk}
          onCancel={handleDateCancel}
          footer={[
            <Button
              style={{
                padding: "10px 15px",
                color: "#fff",
                backgroundColor: "#FF0000",
              }}
              onClick={() => handleDateCancel()}
            >
              Cancel
            </Button>,
            <Button
              style={{
                padding: "10px 15px",
                color: "#fff",
                backgroundColor: "#04681e",
              }}
              onClick={(e) => updateDateClick(e)}
            >
              Confirm
            </Button>,
          ]}
        >
          <div>
            Creation Date:
            <input type="date" onChange={(e) => onChange(e)} />
          </div>
          <div style={{ marginTop: '10px' }}>
            Update Date:
            <input style={{ marginLeft: '8px' }} type="date" onChange={(e) => onUpdateChange(e)} />
          </div>
        </Modal>
        <Modal title="Payment Status"
          open={paymentModalOpen}
          onOk={handlePaymentCancel}
          onCancel={handlePaymentCancel}
          footer={[
            <Button
              style={{
                padding: "10px 15px",
                color: "#fff",
                backgroundColor: "#FF0000",
              }}
              onClick={() => handlePaymentCancel()}
            >
              Cancel
            </Button>,
            <Button
              style={{
                padding: "10px 15px",
                color: "#fff",
                backgroundColor: "#04681e",
              }}
              onClick={(e) => handleUpdatePayment(e)}
            >
              Confirm
            </Button>,
          ]}
        >
          <div>
            Payment Status :
            <Switch style={{ marginLeft: '8px' }} checked={statusOfPayment ? true : false} onChange={() => handlePaymentStatus()} />
          </div>
          <div style={{ marginTop: '10px' }}>
            Payment Remark:
            <input
              style={{ marginLeft: '8px',borderRadius:'10px' }}
              type="text"
              value={remarkOfPayment}

              onChange={(e) => setRemarkOfPayment(e.target.value)}
            />
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ReadyReport;
