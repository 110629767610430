import React, { useEffect, useState } from "react";
import { PiEngineFill } from "react-icons/pi";
import { GrConnect } from "react-icons/gr";
import { GiSpring } from "react-icons/gi";
import { FaDrumSteelpan } from "react-icons/fa6";
import { GiTyre } from "react-icons/gi";
import { FaTachometerAlt } from "react-icons/fa";
import { FaCar } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { GoChecklist } from "react-icons/go";
import { MdOutlineCarCrash } from "react-icons/md";
import { GiCarKey } from "react-icons/gi";
import { BsBank } from "react-icons/bs";
import { FaBook } from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";
import ProgressBar from "react-bootstrap";
import { BsFillLightningFill } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import { IoSettingsOutline } from "react-icons/io5";
import { IoMdPerson } from "react-icons/io";
import { LiaImageSolid } from "react-icons/lia";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom/dist";
import { moment } from "moment";
import { useSelector } from "react-redux";
import "react-circular-progressbar/dist/styles.css";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import RadialSeparators from "./RadialSeparators";
import { PiEngine } from "react-icons/pi";
import { GiGearStick } from "react-icons/gi";


const FarmEquipmentReport = () => {
  const params = useParams();
  const navigate = useNavigate()
  const { organisationLogo } = useSelector((store) => store.orgLogo);
  const { brandLogo } = useSelector((store) => store.brandLogo);
  const [caseinfo, setcaseinfo] = useState("");
  const [casePerfomanceinfo, setcasePerfomanceinfo] = useState("");
  const [caseEngine, setCaseEngine] = useState("");
  const [caseTransmission, setCaseTransmission] = useState("");
  const [caseSuspension, setCaseSuspension] = useState("");
  const [caseBrakes, setCaseBrakes] = useState("");
  const [caseTyre, setCaseTyre] = useState("");
  const [caseInterior, setCaseInterior] = useState("");
  const [caseExterior, setCaseExterior] = useState("");
  const [caseRating, setCaseRating] = useState("");
  const [temploading, setTempLoading] = useState(false);
  const [percentage, setPercentage] = useState(66);
  const [brandSet, SetBrandSet] = useState('')
  //   console.log(casePerfomanceinfo,"1");
  console.log(casePerfomanceinfo, "casePerfomanceinfo");

  const roundImageArray = [
    {
      label: "Poor",
      percentage: 2,
      roundImage: "/assets/Adsum Advisory Services Pvt. Ltd._files/leg02.png",
    },
    {
      label: "Average",
      percentage: 4,
      roundImage: "/assets/Adsum Advisory Services Pvt. Ltd._files/leg04.png",
    },
    {
      label: "Satisfactory",
      percentage: 5,
      roundImage: "/assets/Adsum Advisory Services Pvt. Ltd._files/leg06.png",
    },
    {
      label: "Good",
      percentage: 7,
      roundImage: "/assets/Adsum Advisory Services Pvt. Ltd._files/ovleg07.png",
    },
    {
      label: "Excellent",
      percentage: 9,
      roundImage: "/assets/Adsum Advisory Services Pvt. Ltd._files/leg09.png",
    },
  ];
  const selectImageArray = [
    {
      label: "Poor",
      lineImage: "/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png",
    },
    {
      label: "Average",
      lineImage: "/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png",
    },
    {
      label: "Satisfactory",
      lineImage: "/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png",
    },
    {
      label: "Good",
      lineImage: "/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png",
    },
    {
      label: "Excellent",
      lineImage: "/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png",
    },
  ];

  useEffect(() => {
    const caseId = params.caseId;
    const fetchData = async () => {
      try {
        const url = `${Baseurl}/api/v1/cases/individualcase/${caseId}`;
        const caseFetch = await axios.get(url);
        console.log("hll");
        try {
          if (caseFetch.data.success) {
            const casedetails = caseFetch.data.case;
            SetBrandSet(`${caseFetch.data.case.brand},${caseFetch.data.case.model},${caseFetch.data.case.variant}`)
            setcaseinfo(casedetails);
            const vehicleType = casedetails.assetType;
            const carCasDetails = casedetails.farmEquipment;

            let findEngineCon = "";
            let findTransmissionCon = "";
            let findSuspensionCon = "";
            let findBrakesCon = "";
            let findTyreCon = "";
            let findElectricalCon = "";
            let findExteriorCon = "";
            let finalTyreCondition = "";

            console.log("1");

            let transmission = carCasDetails.transmissionCondition;
            if (vehicleType === "Farm Equipment") {
              findEngineCon = roundImageArray.find(
                (data) =>
                  data.label === carCasDetails.engineCondition
              );
              findTransmissionCon = roundImageArray.find(
                (data) => data.label === carCasDetails.transmissionCondition
              );
              findElectricalCon = roundImageArray.find(
                (data) =>
                  data.label === carCasDetails.electricalCondition
              );
              findExteriorCon = roundImageArray.find(
                (data) =>
                  data.label === carCasDetails.bodyCondition
              );
              findBrakesCon = roundImageArray.find(
                (data) =>
                  data.label === carCasDetails.brakes
              );

              console.log("2");

              const TotalTyreCon =
                Number(carCasDetails.lhRear) +
                Number(carCasDetails.rhRear) +
                Number(carCasDetails.lhFront) +
                Number(carCasDetails.rhFront) +
                Number(carCasDetails.spare)

              findTyreCon = Number(TotalTyreCon) / 50;
              findTyreCon = Math.round(findTyreCon);
              console.log("4");
              if (findTyreCon === 1 || findTyreCon === 2) {
                finalTyreCondition = 2;
              } else if (findTyreCon === 3 || findTyreCon === 4) {
                finalTyreCondition = 4;
              } else if (findTyreCon === 5 || findTyreCon === 6) {
                finalTyreCondition = 5;
              } else if (findTyreCon === 7 || findTyreCon === 8) {
                finalTyreCondition = 7;
              } else if (findTyreCon === 9 || findTyreCon === 10) {
                finalTyreCondition = 9;
              }
              console.log("5");
              const findTyreCondition = roundImageArray.find(
                (data) => data.percentage === finalTyreCondition
              );
              const overalRating =
                Number(findEngineCon.percentage) +
                Number(findTransmissionCon.percentage) +
                Number(findElectricalCon.percentage) +
                Number(findExteriorCon.percentage) +
                Number(finalTyreCondition);

                const overalavg = Number(overalRating) / 7;
              console.log("7");
              setcasePerfomanceinfo({
                findEngineCon: findEngineCon,
                findTransmissionCon: findTransmissionCon,
                findSuspensionCon: findSuspensionCon,
                findBrakesCon: findBrakesCon,
                findTyreCon: findTyreCondition,
                findElectricalCon: findElectricalCon,
                findExteriorCon: findExteriorCon,
                veralrating: overalavg.toFixed(2),
              });
              console.log("8");
            } else {
            }
          }
        } catch (error) { }
      } catch (error) { }
    };
    fetchData();
  }, [params.caseId]);

  console.log(caseinfo, "caseinfo");
  console.log(casePerfomanceinfo.veralrating, "casePerfomanceinfo");


  return (
    <>
      {caseinfo ? (
        <>
          <div>
            <div
              className="content"
              style={{
                border: "1px solid #686868",
                padding: "0 10px",
                width: "100%",
                position: "relative",
              }}
            >
              {/* A4 size */}
              <div className="row noprint p-3">
                <div className="col-sm-6">
                  <a
                    href=""
                    className="btn btn-success"
                    onclick="window.print();"
                  >
                    Print
                  </a>
                </div>
                <div className="col-sm-6 text-right">
                  <a href="" className="btn btn-success">
                    Cancel
                  </a>
                </div>
              </div>
              <div
                className="row"
                style={{
                  padding: "0 10px",
                  fontSize: "12pt",
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className="col-sm-6">
                  Ref. No / Loan No&nbsp;:&nbsp;{caseinfo.loanAgreementNo}
                </div>
                <p></p>
                {caseinfo.caseId > 0 && caseinfo.caseId < 10 ? <> <div className="col-sm-6 text-right">
                  Case ID&nbsp;:&nbsp;0{caseinfo.caseId}
                </div></> : <> <div className="col-sm-6 text-right">
                  Case ID&nbsp;:&nbsp;{caseinfo.caseId}
                </div></>}

              </div>
              <div
                style={{
                  padding: "2px 15px",
                  border: "1px solid #000",
                  width: "100%",
                  height: "auto"
                }}
              >
                <div className="row">
                  <div className="col-sm-3 p-2">
                    <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/toplogo.png" />
                  </div>
                  <div className="col-sm-6 text-center">
                    <h4>
                      <strong>
                        Valuation Report
                        {/* <br /> */}
                        <p> {caseinfo.assetType}</p>
                        {/* <br /> */}
                        <p style={{ fontSize: '12px' }}>
                          {brandSet.length > 20
                            ? brandSet.slice(0, 20) + "..." // Truncate the string if it's longer than 20 characters
                            : brandSet}
                        </p>
                      </strong>
                    </h4>
                    {/* <br /> */}
                    <p> &nbsp;&nbsp;</p>
                  </div>
                  <div className="col-sm-3 text-right">
                    <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/sidelogo.png" />
                  </div>
                </div>
              </div>
              <div style={{ padding: "0px 10px", height: "auto" }}>
                <div className="row">
                  <div className="col-sm-7">
                    {/* ==== main col 7*/}
                    <div className="row" style={{ paddingRight: 15 }}>
                      <div
                        className="col-sm-12 newReport"
                        style={{
                          marginTop: 2,
                          border: "1px solid #000",
                          padding: "3px 5px",
                        }}
                      >
                       <b style={{color:"black"}}>   City : {caseinfo.city}</b>
                      </div>
                    </div>
                    <div className="row" style={{ paddingRight: 15 }}>
                      <div
                        className="col-sm-12 newReport"
                        style={{
                          marginTop: 2,
                          border: "1px solid #000",
                          padding: "3px 5px",
                        }}
                      >
                        <b style={{color:"black"}}>  Color : {caseinfo.vehicleColor}</b>
                      </div>
                    </div>
                    <div className="row" style={{ paddingRight: 15 }}>
                      <div
                        className="col-sm-12 newReport"
                        style={{
                          marginTop: 2,
                          border: "1px solid #000",
                          padding: "3px 5px",
                        }}
                      >
                       <b style={{color:"black"}}>   Odometer in Kms : {caseinfo.meterReading}</b>
                      </div>
                    </div>
                    <div className="row" style={{ paddingRight: 15 }}>
                      <div
                        className="col-sm-12 newReport"
                        style={{
                          marginTop: 2,
                          border: "1px solid #000",
                          padding: "3px 5px",
                        }}
                      >
                       <b style={{color:"black"}}>   Fuel Type : {caseinfo.fueltype}</b>
                      </div>
                    </div>
                    <div className="row" style={{ paddingRight: 15 }}>
                      <div
                        className="col-sm-12 newReport"
                        style={{
                          marginTop: 2,
                          border: "1px solid #000",
                          padding: "3px 5px",
                        }}
                      >
                         <b style={{color:"black"}}> Transmission :{" "}
                        {caseinfo.farmEquipment.transmissionType}</b>
                      </div>
                    </div>
                    <div className="row" style={{ paddingRight: 15 }}>
                      <div
                        className="col-sm-12 newReport"
                        style={{
                          marginTop: 2,
                          border: "1px solid #000",
                          padding: "3px 5px",
                        }}
                      >
                       <b style={{color:"black"}}>   Accidental status : {caseinfo.farmEquipment.accidental}</b>
                      </div>
                    </div>
                    <div className="row" style={{ paddingRight: 15 }}>
                      <div
                        className="col-sm-12 newReport"
                        style={{
                          padding: "3px 5px",
                          backgroundColor: "#d6d6d6",
                        }}
                      >
                        <span style={{ color: "#000", fontWeight: "bold" }}>
                        <b style={{color:"black"}}>   Ex. Showroom Price&nbsp;:{caseinfo.marketPrice}</b>
                        </span>
                      </div>
                    </div>
                    <div className="row" style={{ paddingRight: 15 }}>
                      <div
                        className="col-sm-12 newReport"
                        style={{
                          padding: "3px 5px",
                          backgroundColor: "#d6d6d6",
                        }}
                      >
                        <span style={{ color: "#000", fontWeight: "bold" }}>
                        <b style={{color:"black"}}>   Estimated Market Value :{caseinfo.valuation}</b>
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* ==== main col 7 end */}
                  <div className="col-sm-5 text-center imfix">
                    <img
                      src={caseinfo.images.frontImage}
                      className="fixed-img"
                      style={{ height: '230px', width: '250px' }}
                    />
                  </div>
                </div>
              </div>
              {/* ====================================================================== */}
              <div className="row" style={{ padding: "2px 1px", }}>
                <div
                  className="col-sm-12 newReport"
                  style={{ border: "1px solid #000", borderRadius: 2 }}
                >
                  <table width="100%" style={{ padding: "5px 5px" }}>
                    <tbody>
                      <tr className="text-center" style={{ height: "10px" }}>
                        <td width="12%">
                          <div className="center-round">

                            <div className="legcontainer">
                              <img
                                src={casePerfomanceinfo.findEngineCon.roundImage}
                                alt="Small Image"
                                className="small-image"
                              />
                              <img
                                src="/assets/Adsum Advisory Services Pvt. Ltd._files/engine.png"
                                className="image1"
                                alt="Image 1"
                              />
                            </div>
                          </div>
                        </td>
                        <td width="12%">
                          <div className="center-round">
                            <div className="legcontainer">
                              <img
                                src={
                                  casePerfomanceinfo.findTransmissionCon
                                    .roundImage
                                }
                                alt="Small Image"
                                className="small-image"
                              />
                              <img
                                src="/assets/Adsum Advisory Services Pvt. Ltd._files/transm.png"
                                className="image1"
                                alt="Image 1"
                              />
                            </div>
                          </div>
                        </td>
                        <td width="12%">
                          <div className="center-round">
                            <div className="legcontainer">
                              <img
                                src={casePerfomanceinfo.findElectricalCon.roundImage}
                                alt="Small Image"
                                className="small-image"
                              />
                              <img
                                src="/assets/Adsum Advisory Services Pvt. Ltd._files/electri.png"
                                className="image1"
                                alt="Image 1"
                              />
                            </div>
                          </div>
                        </td>
                        <td width="12%">
                          <div className="center-round">
                            <div className="legcontainer">
                              <img
                                src={casePerfomanceinfo.findTyreCon.roundImage}
                                alt="Small Image"
                                className="small-image"
                              />
                              <img
                                src="/assets/Adsum Advisory Services Pvt. Ltd._files/tyres.png"
                                className="image1"
                                alt="Image 1"
                              />
                            </div>
                          </div>
                        </td>
                        <td width="12%">
                          <div className="center-round">
                            <div className="legcontainer">
                              <img
                                src={casePerfomanceinfo.findExteriorCon.roundImage}
                                alt="Small Image"
                                className="small-image"
                              />
                              <img
                                src="/assets/Adsum Advisory Services Pvt. Ltd._files/Exterior.png"
                                className="image1"
                                alt="Image 1"
                              />
                            </div>
                          </div>
                        </td>
                        <td width="12%">
                          {casePerfomanceinfo.veralrating > '0.01' &&
                            casePerfomanceinfo.veralrating < '2' ? (
                            <>
                              <div className="center-round">
                                <div className="legcontainer">
                                  <img
                                    //   src="/assets/Adsum Advisory Services Pvt. Ltd._files/ovleg07.png "
                                    src="/assets/Adsum Advisory Services Pvt. Ltd._files/leg02.png"
                                    alt="Small Image"
                                    className="small-image"
                                  />
                                  <h1
                                    className="image1"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    {casePerfomanceinfo.veralrating}
                                  </h1>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {casePerfomanceinfo.veralrating > 2.01 &&
                                casePerfomanceinfo.veralrating < 4 ? (
                                <>
                                  <div className="center-round">
                                    <div className="legcontainer">
                                      <img
                                        //   src="/assets/Adsum Advisory Services Pvt. Ltd._files/ovleg07.png "
                                        src="/assets/Adsum Advisory Services Pvt. Ltd._files/leg04.png"
                                        alt="Small Image"
                                        className="small-image"
                                      />
                                      <h1
                                        className="image1"
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        {casePerfomanceinfo.veralrating}
                                      </h1>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {casePerfomanceinfo.veralrating > 4.01 &&
                                    casePerfomanceinfo.veralrating < 6 ? (
                                    <>
                                      <div className="center-round">
                                        <div className="legcontainer">
                                          <img
                                            //   src="/assets/Adsum Advisory Services Pvt. Ltd._files/ovleg07.png "
                                            src="/assets/Adsum Advisory Services Pvt. Ltd._files/leg06.png"
                                            alt="Small Image"
                                            className="small-image"
                                          />
                                          <div>
                                            <h1
                                              className="image1"
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                              }}
                                            >
                                              {casePerfomanceinfo.veralrating}
                                            </h1>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {casePerfomanceinfo.veralrating > 6.01 &&
                                        casePerfomanceinfo.veralrating < 8 ? (
                                        <>
                                          <div className="center-round">
                                            <div className="legcontainer">
                                              <img
                                                src="/assets/Adsum Advisory Services Pvt. Ltd._files/ovleg07.png "
                                                // src="/assets/Adsum Advisory Services Pvt. Ltd._files/leg07.png"
                                                alt="Small Image"
                                                className="small-image"
                                              />
                                              <h1
                                                className="image1"
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                              >
                                                {casePerfomanceinfo.veralrating}
                                              </h1>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div className="center-round">
                                            <div className="legcontainer">
                                              <img
                                                //   src="/assets/Adsum Advisory Services Pvt. Ltd._files/ovleg07.png "
                                                src="/assets/Adsum Advisory Services Pvt. Ltd._files/leg09.png"
                                                alt="Small Image"
                                                className="small-image"
                                              />
                                              <h1
                                                className="image1"
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                              >
                                                {casePerfomanceinfo.veralrating}
                                              </h1>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </td>
                      </tr>
                      <tr className="legtext" style={{ height: "10px" }}>
                        <td>
                          Engine
                          {/* <br /> */}
                          <p>{casePerfomanceinfo.findEngineCon.percentage}/10</p>
                        </td>
                        <td>
                          Transmission
                          {/* <br /> */}
                          <p> {casePerfomanceinfo.findTransmissionCon.percentage}/10</p>
                        </td>
                        <td>
                          Electrical
                          {/* <br /> */}
                          <p>{casePerfomanceinfo.findElectricalCon.percentage}/10</p>
                        </td>
                        <td>
                          Tyres
                          {/* <br /> */}
                          <p>{casePerfomanceinfo.findTyreCon.percentage}/10</p>
                        </td>
                        <td>
                          Exterior
                          {/* <br /> */}
                          <p>{casePerfomanceinfo.findElectricalCon.percentage}/10</p>
                        </td>
                        <td>
                          <strong>
                            OVERALL
                            {/* <br /> */}
                            <p> {casePerfomanceinfo.veralrating} of 10</p>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* ====================================================================== */}
              <div className="row">
                <div className="col-sm-12 newReport">
                  <table
                    width="100%"
                    border={1}
                    style={{ fontSize: "10pt", border: "1px solid #688", height: "auto" }}
                  >
                    <tbody>
                      <tr className="p-3" style={{ height: "5px" }}>
                        <td width="75%" rowSpan={2}>
                          &nbsp;&nbsp;&nbsp;&nbsp;Inspection Address
                          {/* <br /> */}
                          <p>&nbsp;&nbsp;&nbsp;&nbsp;{caseinfo.vehicleLocation}</p>
                        </td>
                        <td className="text-center">
                          Created Date
                          {/* <br /> */}
                          <p> {caseinfo.createdAt.slice(0, 10)}</p>
                        </td>
                      </tr>
                      <tr style={{ height: "5px" }}>
                        <td className="text-center">
                          Service Date
                          {/* <br /> */}
                          <p> {caseinfo.submittedAt.slice(0, 10)}</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* <div style={{ padding: 8 }} /> */}
              <table>
                <tbody>
                  <tr style={{ height: "10px" }}>
                    <td>
                      <div className="imgcontainer">
                        <img
                          src="/assets/Adsum Advisory Services Pvt. Ltd._files/car01.jpg"
                          className="image3"
                        />
                      </div>
                    </td>
                    <td>
                      <strong>&nbsp;&nbsp;VEHICLE DETAILS</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="row">
                <div className="col-sm-12 newReport">
                  {/* *=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*= */}
                  <table width="100%">
                    <tbody>
                      <tr style={{ verticalAlign: "top", height: "20px" }}>
                        <td width="24%">
                          <div className="col-sm-12 newReport column-ht" style={{ paddingTop: '0px', height: '100%' }}>
                            <img
                              src="/assets/Adsum Advisory Services Pvt. Ltd._files/car08.jpg"
                              width="auto"
                              height="10%"
                            />
                            {/* <br /> */}
                            <p style={{ fontSize: '12px', color: '#000', fontWeight: '500' }}>
                              Registration
                            </p>
                            {/* <br /> */}
                            <p style={{ fontSize: '12px', color: '#000', fontWeight: '600' }}>{caseinfo.regdNo}</p>
                            <hr />
                            <p style={{ fontSize: '12px', color: '#000', fontWeight: '500' }}>
                              Chasis Number
                            </p>
                            {/* <br /> */}
                            <p style={{ fontSize: '12px', color: '#000', fontWeight: '600' }}>
                              {caseinfo.chasisNo}
                              {/* <br /> */}
                            </p>
                            <hr />
                            <p style={{ fontSize: '12px', color: '#000', fontWeight: '500' }}>
                              Engine Number
                            </p>
                            {/* <br /> */}
                            <p style={{ fontSize: '12px', color: '#000', fontWeight: '600' }}>
                              {caseinfo.engNo}
                            </p>
                            <hr />
                          </div>
                        </td>
                        <td width="24%">
                          <div className="col-sm-12 newReport column-ht" style={{ paddingTop: '0px', height: '100%' }}>
                            <img
                              src="/assets/Adsum Advisory Services Pvt. Ltd._files/car02.jpg"
                              width="auto"
                              height="10%"
                            />
                            {/* <br /> */}
                            <p style={{ fontSize: '12px', color: '#000', fontWeight: '500' }}>
                              Body Type
                            </p>
                            {/* <br /> */}
                            <p style={{ fontSize: '12px', color: '#000', fontWeight: '600' }}>
                              {caseinfo.bodyType}
                            </p>
                            <hr />
                            <p style={{ fontSize: '12px', color: '#000', fontWeight: '500' }}>
                              Manufacture Year
                            </p>
                            {/* <br /> */}
                            <p style={{ fontSize: '12px', color: '#000', fontWeight: '600' }}>
                              {caseinfo.manufacturingDate}
                            </p>
                            <p style={{ fontSize: '12px', color: '#000', fontWeight: '500' }}>
                              Date of Registration
                            </p>
                            {/* <br /> */}
                            <p style={{ fontSize: '12px', color: '#000', fontWeight: '600' }}>
                              {caseinfo.regdDate}

                            </p>
                            <hr />
                            <p style={{ fontSize: '12px', color: '#000', fontWeight: '500' }}>
                              Vehicle Type
                            </p>
                            {/* <br /> */}
                            <p style={{ fontSize: '12px', color: '#000', fontWeight: '600' }}>
                              {caseinfo.assetType}
                            </p>
                          </div>
                        </td>
                        <td width="25%">
                          <div className="col-sm-12 newReport column-ht" style={{ paddingTop: '0px', height: '100%' }}>
                            <img
                              src="/assets/Adsum Advisory Services Pvt. Ltd._files/car03.jpg"
                              width="auto"
                              height="10%"
                            />
                            {/* <br /> */}
                            <p style={{ fontSize: '12px', color: '#000', fontWeight: '500' }}>
                              Owner Name
                            </p>
                            {/* <br /> */}
                            <p style={{ fontSize: '13px', fontWeight: '500', color: '#000' }}>
                              {caseinfo.regdOwner}
                            </p>
                            <hr />
                            <p style={{ fontSize: '12px', color: '#000', fontWeight: '500' }}>
                              Owner Serial No
                            </p>
                            {/* <br /> */}
                            <p style={{ fontSize: '12px', color: '#000', fontWeight: '600' }}>
                              {caseinfo.ownerSerialNo}
                            </p>
                            <hr />
                            <p style={{ fontSize: '12px', color: '#000', fontWeight: '500' }}>
                              Proposed Owner Name
                            </p>
                            {/* <br /> */}
                            <p style={{ fontSize: '12px', color: '#000', fontWeight: '600' }}>
                              {caseinfo.name}
                            </p>
                            <hr />
                            {/* <br /> */}
                          </div>
                        </td>
                        <td width="25%">
                          <div className="col-sm-12 newReport column-ht" style={{ paddingTop: '0px', height: '100%' }}>
                            <img
                              src="/assets/Adsum Advisory Services Pvt. Ltd._files/car04.jpg"
                              width="auto"
                              height="10%"
                            />
                            {/* <br /> */}
                            <p style={{ fontSize: '12px', color: '#000', fontWeight: '500' }}>
                              Report Requested by
                            </p>
                            {/* <br /> */}
                            <p style={{ fontSize: '12px', color: '#000', fontWeight: '600' }}>{caseinfo.requesterName}</p>
                            <hr />
                            <p style={{ fontSize: '12px', color: '#000', fontWeight: '500' }}>
                              Case Type
                            </p>
                            {/* <br /> */}
                            <p style={{ fontSize: '12px', color: '#000', fontWeight: '600' }}>
                              {caseinfo.caseType}

                            </p>
                            <hr />
                            <p style={{ fontSize: '12px', color: '#000', fontWeight: '500' }}>
                              Purpose Of Valuation
                            </p>
                            {/* <br /> */}
                            <p style={{ fontSize: '12px', color: '#000', fontWeight: '600' }}>
                              {caseinfo.valuationPurpose}

                            </p>
                            <hr />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {/* *=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*= */}
                  <div style={{ padding: 2 }} />
                  <table border={1} width="100%">
                    <tbody>
                      <tr
                        className="text-center"
                        style={{ border: "1px solid black", height: "20px" }}
                      >
                        <td
                          width="50%"
                          style={{ borderRight: "1px solid #686868" }}
                        >
                          Insurance Company
                          {/* <br /> */}
                          <p><strong>{caseinfo.insuranceCompany}</strong></p>
                        </td>
                        <td width="25%">
                          Insurance Status
                          {/* <br /> */}
                          <div>
                            {caseinfo.insuranceStatus === "Yes" ? (
                              <strong>Expired</strong>
                            ) : (
                              <strong>Running</strong>
                            )}
                          </div>
                        </td>
                        <td style={{ borderLeft: "1px solid #686868" }}>
                          Insurance Valid Till
                          {/* <br /> */}
                          <p> <strong>
                            {caseinfo.vehicleEdetails.vehicleInsuranceUpto}
                          </strong></p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {/* <div style={{ padding: 5 }} /> */}
                  <table width="100%">
                    <tbody>
                      <tr style={{ height: "5px" }}>
                        <td width="65%" style={{ border: "1px solid #686868" }}>
                        <p> &nbsp;&nbsp;Remarks&nbsp;:&nbsp;{caseinfo.yourRemark}</p>
                        <p> &nbsp;&nbsp;Extrior Remarks&nbsp;:&nbsp;{caseinfo.extriorRemark}</p>
                          <a className="ps-2" target="_blank" href={caseinfo.videos} style={{ color: 'blue', textDecoration: 'underline' }}>
                            Preview Video Clip
                          </a>
                          &nbsp;
                          <a style={{ color: 'blue', textDecoration: 'underline' }} target="_blank"
                            href={caseinfo.imageUrl}
                          >
                            Preview Images
                          </a>
                        </td>
                        <td
                          width="35%"
                          rowSpan={2}
                          style={{ border: "1px solid black" }}
                        >
                          <div className="text-center">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src="/assets/stamp.jpeg"
                                width="50px"
                                height="50px"
                              />
                            </div>
                            <div>Authorized Signatory</div>
                            {/* <br /> */}
                          </div>
                        </td>
                      </tr>
                      <tr style={{ height: "10px" }}>
                        <td width="65%" style={{ border: "1px solid #686868" }}>
                          &nbsp;&nbsp;Field Service By:&nbsp;
                          {caseinfo.fieldServiceBy}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* page footer ends */}
              {/* <div style={{ padding: 5 }} /> */}
            </div>

            <div className="page-break" />
            <div
              className="content"
              style={{
                margin: "30px 0 0 0",
                padding: "0 10px",
                width: "100%",
                border: "1px solid #000",
                height: "auto"
              }}
            >
              <div style={{ marginTop: 5 }}></div>
              {/*-  header */}
              <div className="row">
                <div className="col-sm-12 newReport">

                  <table
                    className="rcrowheight rtborder"
                    style={{ borderCollapse: "collapse" }}
                    width="100%"
                  >
                    <tbody>
                      <tr style={{ height: "auto" }}>
                        <td>
                          <strong>REGISTRATION DETAILS</strong>
                        </td>
                        <td>
                          <strong>AS PER VAHAN</strong>
                        </td>
                        <td>
                          <strong>AS PER RC</strong>
                        </td>
                        <td>
                          <strong>AS PER ACTUAL</strong>
                        </td>
                      </tr>
                      <tr style={{ height: "5px" }}>
                        <td width="35%">Vehicle Registration No</td>
                        <td>{caseinfo.vehicleEdetails.regNo}</td>
                        <td>{caseinfo.vehicleEdetails.regNo}</td>
                        <td>{caseinfo.regdNo}</td>
                      </tr>
                      <tr style={{ height: "10px" }}>
                        <td width="35%" style={{ fontSize: '10px', fontWeight: '800' }}>Vehicle Mfg Name </td>
                        <td>{caseinfo.vehicleEdetails.vehicleManufacturerName}</td>
                        <td>{caseinfo.vehicleEdetails.vehicleManufacturerName}</td>
                        <td>{caseinfo.vehicleEdetails.vehicleManufacturerName}</td>
                      </tr>
                      <tr style={{ height: "10px" }}>
                        <td width="35%" style={{ fontSize: '10px', fontWeight: '800' }}>Vehicle Model </td>
                        <td>{caseinfo.vehicleEdetails.model}</td>
                        <td>{caseinfo.vehicleEdetails.model}</td>
                        <td>{caseinfo.vehicleEdetails.model}</td>
                      </tr>
                      <tr style={{ height: "5px" }}>
                        <td width="35%">Chasis No&nbsp;:&nbsp;</td>
                        <td>{caseinfo.vehicleEdetails.chassis}</td>
                        <td>{caseinfo.vehicleEdetails.chassis}</td>
                        <td>{caseinfo.chasisNo}</td>
                      </tr>
                      <tr style={{ height: "5px" }}>
                        <td width="35%">Engine No&nbsp;:&nbsp;</td>
                        <td> {caseinfo.vehicleEdetails.engine}</td>
                        <td>{caseinfo.vehicleEdetails.engine}</td>
                        <td>{caseinfo.engNo}</td>
                      </tr>
                      <tr style={{ height: "5px" }}>
                        <td width="35%">Vehicle Color</td>
                        <td>{caseinfo.vehicleEdetails.vehicleColour}</td>
                        <td>{caseinfo.vehicleEdetails.vehicleColour}</td>
                        <td>{caseinfo.vehicleColor}</td>
                      </tr>
                      <tr style={{ height: "5px" }}>
                        <td width="35%">Fuel Type&nbsp;:&nbsp;</td>
                        <td>{caseinfo.vehicleEdetails.type}</td>
                        <td>{caseinfo.vehicleEdetails.type}</td>
                        <td>{caseinfo.fueltype}</td>
                      </tr>

                      <tr style={{ height: "5px" }}>
                        <td width="35%">Owner Serial No</td>
                        <td>{caseinfo.vehicleEdetails.ownerCount}</td>
                        <td>{caseinfo.vehicleEdetails.ownerCount}</td>
                        <td>{caseinfo.ownerSerialNo}</td>
                      </tr>
                      <tr style={{ height: "5px" }}>
                        <td width="35%">Reg Owner Name</td>
                        <td>{caseinfo.vehicleEdetails.owner}</td>
                        <td>{caseinfo.vehicleEdetails.owner}</td>
                        <td>{caseinfo.regdOwner}</td>
                      </tr>
                      <tr style={{ height: "5px" }}>
                        <td width="35%">Date Of Registration</td>
                        <td> {caseinfo.vehicleEdetails.regDate}</td>
                        <td> {caseinfo.vehicleEdetails.regDate}</td>
                        <td>{caseinfo.regdDate}</td>
                      </tr>
                      <tr style={{ height: "10px" }}>
                        <td width="35%">RTA Name</td>
                        <td> {caseinfo.vehicleEdetails.regAuthority}</td>
                        <td> {caseinfo.vehicleEdetails.regAuthority}</td>
                        <td>-</td>
                      </tr>
                      <tr style={{ height: "5px" }}>
                        <td width="35%">Year Of Manufacture</td>
                        <td>{caseinfo.vehicleEdetails.vehicleManufacturingMonthYear}</td>
                        <td>{caseinfo.vehicleEdetails.vehicleManufacturingMonthYear}</td>
                        <td>{caseinfo.manufacturingDate}</td>
                      </tr>
                      <tr style={{ height: "5px" }}>
                        <td width="35%">Fitness Expiry Date</td>
                        <td>{caseinfo.vehicleEdetails.rcExpiryDate}</td>
                        <td>{caseinfo.vehicleEdetails.rcExpiryDate}</td>
                        <td>{caseinfo.farmEquipment.fitnessExpiryDate}</td>
                      </tr>
                      <tr style={{ height: "5px" }}>
                        <td width="35%">Insurance Exp Date</td>
                        <td>{caseinfo.vehicleEdetails.vehicleInsuranceUpto}</td>
                        <td>{caseinfo.vehicleEdetails.vehicleInsuranceUpto}</td>
                        <td>{caseinfo.vehicleEdetails.insuranceExpiry}</td>
                      </tr>
                      <tr style={{ height: "10px" }}>
                        <td width="35%">Tax Valid Up To</td>
                        <td>{caseinfo.vehicleEdetails.vehicleTaxUpto}</td>
                        <td>{caseinfo.vehicleEdetails.vehicleTaxUpto}</td>
                        <td>{caseinfo.farmEquipment.roadtaxValidityDate}</td>
                      </tr>
                      <tr style={{ height: "10px" }}>
                        <td width="35%">Permit Up To</td>
                        <td>{caseinfo.vehicleEdetails.permitValidUpto}</td>
                        <td>{caseinfo.vehicleEdetails.permitValidUpto}</td>
                        <td>-</td>
                      </tr>
                      <tr style={{ height: "10px" }}>
                        <td width="35%">Permit Type</td>
                        <td>{caseinfo.vehicleEdetails.permitType}</td>
                        <td>{caseinfo.vehicleEdetails.permitType}</td>
                        <td>-</td>
                      </tr>
                      <tr style={{ height: "10px" }}>
                        <td width="35%">Hypothecation</td>
                        <td>{caseinfo.vehicleEdetails.rcFinancer}</td>
                        <td>{caseinfo.vehicleEdetails.rcFinancer}</td>
                        <td> {caseinfo.hypothecationBank}</td>
                      </tr>
                      <tr style={{ height: "5px" }}>
                        <td width="35%">Cubic Capacity</td>
                        <td>{caseinfo.vehicleEdetails.vehicleCubicCapacity}</td>
                        <td>{caseinfo.vehicleEdetails.vehicleCubicCapacity}</td>
                        <td>{caseinfo.cubicCapacity}</td>
                      </tr>
                      <tr style={{ height: "5px" }}>
                        <td width="35%">Gross Vehicle Weight</td>
                        <td>{caseinfo.vehicleEdetails.grossVehicleWeight}</td>
                        <td>{caseinfo.vehicleEdetails.grossVehicleWeight}</td>
                        <td>{caseinfo.vehicleWeight}</td>
                      </tr>
                      <tr style={{ height: "5px" }}>
                        <td width="35%">Seating Capacity</td>
                        <td> {caseinfo.vehicleEdetails.vehicleSeatCapacity}</td>
                        <td> {caseinfo.vehicleEdetails.vehicleSeatCapacity}</td>
                        <td>{caseinfo.seatingCapacity}</td>
                      </tr>
                      <tr style={{ height: "5px" }}>
                        <td width="35%">Number Plate</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>{caseinfo.numberPlate}</td>
                      </tr>
                      <tr style={{ height: "5px" }}>
                        <td width="35%">Status</td>
                        <td>{caseinfo.vehicleEdetails.status}</td>
                        <td>{caseinfo.vehicleEdetails.status}</td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr style={{ height: "5px" }}>
                        <td width="35%">Blacklist Status</td>
                        <td>{caseinfo.vehicleEdetails.blacklistStatus}</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr style={{ height: "5px" }}>
                        <td width="35%">Is Commercial</td>
                        {caseinfo.assetType === "Commercial" ? (
                          <td>Yes</td>
                        ) : (
                          <td>no</td>
                        )}
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr style={{ height: "5px" }}>
                        <td width="35%">Challan Details</td>
                        <td>NA</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                  <h4 style={{ marginTop: "5px" }}>
                    <strong>

                      OTHERS:
                    </strong>
                  </h4>
                  <table className="rowheight" width="100%">
                    <tbody>
                      <tr style={{ height: "5px" }}>
                        <td width="35%">Document</td>
                        <td colSpan={2}> {caseinfo.documentVerification}</td>
                      </tr>
                      <tr style={{ height: "5px" }}>
                        <td width="35%">Asset Type</td>
                        <td colSpan={2}>{caseinfo.assetType}</td>
                      </tr>
                      <tr style={{ height: "5px" }}>
                        <td width="35%">Manufacturing Year</td>
                        <td colSpan={2}> {caseinfo.manufacturingDate}</td>
                      </tr>
                    </tbody>
                  </table>
                  <h4 style={{ marginTop: "5px" }}>
                    <strong>

                      DOCUMENT DETAILS:
                    </strong>
                  </h4>
                  <table className="rowheight" width="100%" height="auto">
                    <tbody>
                      <tr style={{ height: "5px" }}>
                        <td width="35%">Fitness Expiry Date</td>
                        <td colSpan={2}>
                          {" "}
                          {caseinfo.farmEquipment.fitnessExpiryDate}
                        </td>
                      </tr>
                      <tr style={{ height: "5px" }}>
                        <td width="35%">Insurance</td>
                        <td colSpan={2}>{caseinfo.vehicleEdetails.vehicleInsuranceUpto}</td>
                      </tr>
                      <tr style={{ height: "5px" }}>
                        <td width="35%">Road Tax Validity Upto</td>
                        <td colSpan={2}>
                          {" "}
                          {caseinfo.farmEquipment.roadtaxValidityDate}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* <div style={{ marginTop: 10 }}>&nbsp;</div> */}
            </div>
            {/* <!-- page 2 ends --> */}

            <div className="page-break" />
            <div
              className="content"
              style={{
                margin: "30px 0 0 0",
                padding: "0 10px",
                width: "100%",
                border: "1px solid #000",
                height: "auto"
              }}
            >
              {/*-  header */}
              <h4 style={{ marginTop: "5px" }}>
                <strong>
                  OTHER DETAILS:
                </strong>
              </h4>
              <table className="rowheight" width="100%" height="auto">
                <tbody>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Tractor Application</td>
                    <td width="30%">{caseinfo.farmEquipment.tractorApplication}</td>
                    <td width="30%"></td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Power (Hp)</td>
                    <td width="30%">{caseinfo.farmEquipment.powerHp}</td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Fuel Type</td>
                    <td width="30%">
                      {" "}
                      {caseinfo.fueltype}
                    </td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Odometer Type</td>
                    <td width="30%">{caseinfo.farmEquipment.odometerType}</td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Meter Reading</td>
                    <td width="30%">
                      {
                        caseinfo.meterReading === 0 ? 'Not Active' :
                          caseinfo.meterReading
                      }
                    </td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Vehicle Color</td>
                    <td width="30%">{caseinfo.vehicleColor}</td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Seating Capacity</td>
                    <td width="30%">{caseinfo.seatingCapacity}</td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Unladen Weight</td>
                    <td width="30%">{caseinfo.vehicleEdetails.unladenWeight}</td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Number plate</td>
                    <td width="30%">{caseinfo.numberPlate}</td>
                  </tr>
                </tbody>
              </table>
              <h4 style={{ marginTop: "5px" }}>
                <strong>
                  ENGINE:
                </strong>
              </h4>
              <table className="rowheight" width="100%" height="auto">
                <tbody>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Engine Status</td>
                    <td width="30%">{caseinfo.farmEquipment.engineStatus}</td>
                    <td width="30%">
                      {caseinfo.farmEquipment.engineStatus === "Yes" ? (
                        <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/right.jpg" />
                      ) : (
                        <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/wrong.jpg" />
                      )}
                    </td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Engine Condition</td>
                    <td width="30%">{caseinfo.farmEquipment.engineCondition}</td>
                    <td width="30%">
                      {
                        caseinfo.farmEquipment.engineCondition === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.engineCondition === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.engineCondition === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.engineCondition === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.engineCondition === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
              <h4 style={{ marginTop: "5px" }}>
                <strong>
                  TRANSMISSION:
                </strong>
              </h4>
              <table className="rowheight" width="100%" height="auto">
                <tbody>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Transmission Type</td>
                    <td width="30%">{caseinfo.farmEquipment.transmissionType}</td>
                    <td width="30%">
                      <span>&nbsp;</span>
                    </td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">                    Transmission Condition</td>
                    <td width="30%">{caseinfo.farmEquipment.transmissionCondition}</td>
                    <td width="30%">
                      {
                        caseinfo.farmEquipment.transmissionCondition === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.transmissionCondition === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.transmissionCondition === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.transmissionCondition === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.transmissionCondition === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
              <h4 style={{ marginTop: "5px" }}>
                <strong>
                  VEHICLE EXTERIOR
                </strong>
              </h4>
              <table className="rowheight" width="100%" height="auto">
                <tbody>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Steering</td>
                    <td width="30%">{caseinfo.farmEquipment.steering}</td>
                    <td width="30%">
                      {
                        caseinfo.farmEquipment.steering === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.steering === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.steering === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.steering === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.steering === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Brakes</td>
                    <td width="30%">{caseinfo.farmEquipment.steering}</td>
                    <td width="30%">
                      {
                        caseinfo.farmEquipment.steering === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.steering === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.steering === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.steering === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.steering === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Seat Condition</td>
                    <td width="30%">{caseinfo.farmEquipment.seatCondition}</td>
                    <td width="30%">
                      {
                        caseinfo.farmEquipment.seatCondition === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.seatCondition === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.seatCondition === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.seatCondition === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.seatCondition === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Body Condition</td>
                    <td width="30%">{caseinfo.farmEquipment.bodyCondition}</td>
                    <td width="30%">
                      {
                        caseinfo.farmEquipment.bodyCondition === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.bodyCondition === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.bodyCondition === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.bodyCondition === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.bodyCondition === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
              <h4 style={{ marginTop: "5px" }}>
                <strong>
                  TYRES & CONDITION
                </strong>
              </h4>
              <table className="rowheight" width="100%" height="auto">
                <tbody>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Type</td>
                    <td width="30%">Tyres</td>
                    <td width="30%">Tyres</td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Lh Front</td>
                    <td width="30%">{caseinfo.farmEquipment.lhFront}</td>
                    <td width="30%">
                      {
                        caseinfo.farmEquipment.lhFront >= '0' && caseinfo.farmEquipment.lhFront <= 20 ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.lhFront >= '20.01' && caseinfo.farmEquipment.lhFront <= '40' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.lhFront >= '40.01' && caseinfo.farmEquipment.lhFront <= '60' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.lhFront >= '60.01' && caseinfo.farmEquipment.lhFront <= '80' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.lhFront >= '80.01' && caseinfo.farmEquipment.lhFront <= '100' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Rh Front</td>
                    <td width="30%">{caseinfo.farmEquipment.rhFront}</td>
                    <td width="30%">
                      {
                        caseinfo.farmEquipment.rhFront >= '0' && caseinfo.farmEquipment.rhFront <= 20 ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.rhFront >= '20.01' && caseinfo.farmEquipment.rhFront <= '40' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.rhFront >= '40.01' && caseinfo.farmEquipment.rhFront <= '60' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.rhFront >= '60.01' && caseinfo.farmEquipment.rhFront <= '80' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.rhFront >= '80.01' && caseinfo.farmEquipment.rhFront <= '100' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Lh Rear</td>
                    <td width="30%">{caseinfo.farmEquipment.lhRear}</td>
                    <td width="30%">
                      {
                        caseinfo.farmEquipment.lhRear >= '0' && caseinfo.farmEquipment.lhRear <= 20 ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.lhRear >= '20.01' && caseinfo.farmEquipment.lhRear <= '40' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.lhRear >= '40.01' && caseinfo.farmEquipment.lhRear <= '60' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.lhRear >= '60.01' && caseinfo.farmEquipment.lhRear <= '80' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.lhRear >= '80.01' && caseinfo.farmEquipment.lhRear <= '100' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Rh Rear</td>
                    <td width="30%">{caseinfo.farmEquipment.rhRear}</td>
                    <td width="30%">
                      {
                        caseinfo.farmEquipment.rhRear >= '0' && caseinfo.farmEquipment.rhRear <= 20 ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.rhRear >= '20.01' && caseinfo.farmEquipment.rhRear <= '40' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.rhRear >= '40.01' && caseinfo.farmEquipment.rhRear <= '60' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.rhRear >= '60.01' && caseinfo.farmEquipment.rhRear <= '80' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.rhRear >= '80.01' && caseinfo.farmEquipment.rhRear <= '100' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                  {
                    caseinfo.farmEquipment.spareTyre > '0' ?
                      <tr style={{ height: "5px" }}>
                        <td width="35%">Spare</td>
                        <td width="30%">{caseinfo.farmEquipment.spare}</td>
                        <td width="30%">
                          {
                            caseinfo.farmEquipment.spare >= '0' && caseinfo.farmEquipment.spare <= 20 ?
                              <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                          }
                          {
                            caseinfo.farmEquipment.spare >= '20.01' && caseinfo.farmEquipment.spare <= '40' ?
                              <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                          }
                          {
                            caseinfo.farmEquipment.spare >= '40.01' && caseinfo.farmEquipment.spare <= '60' ?
                              <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                          }
                          {
                            caseinfo.farmEquipment.spare >= '60.01' && caseinfo.farmEquipment.spare <= '80' ?
                              <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                          }
                          {
                            caseinfo.farmEquipment.spare >= '80.01' && caseinfo.farmEquipment.spare <= '100' ?
                              <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                          }
                        </td>
                      </tr> : <></>
                  }
                </tbody>
              </table>
              <table className="rowheight" width="100%" height="auto">
                <tbody>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Total Tyres Ex Spare</td>
                    <td width="30%">{caseinfo.farmEquipment.totalTyre}</td>
                    <td width="30%">-</td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Available Tyres</td>
                    <td width="30%">{caseinfo.farmEquipment.availableTyre}</td>
                    <td width="30%">-</td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Missing Tyres</td>
                    <td width="30%">{caseinfo.farmEquipment.missingTyre}</td>
                    <td width="30%">-</td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Spare Tyre</td>
                    <td width="30%">{caseinfo.farmEquipment.spareTyre}</td>
                    <td width="30%">-</td>
                  </tr>
                </tbody>
              </table>
              <div className="row">
                <div className="col-sm-12 newReport">
                  <div
                    style={{
                      padding: 2,
                      border: "1px solid #000",
                      width: "100%",
                      height: "auto"
                    }}
                  >
                    {/* page footer */}
                    <div className="col-sm-12 newReport text-center">
                      <strong>Head Office:</strong>
                      <br />
                      Door No. 411,4th Floor, Eden Amsri Square,beside Apollo Hospital, Sangeet Circle,
                      <br />
                      Secunderabad 500003, Telengana
                    </div>
                  </div>
                </div>
                {/* page footer ends */}
              </div>
              {/* <div className="row">
                <div className="col-sm-12 newReport">
                  <div
                    style={{
                      padding: 2,
                      border: "1px solid #000",
                      width: "100%",
                    }}
                  >
                 
                    <div className="col-sm-12 newReport text-center">
                      <strong>Head Office:</strong>
                      <br />
                      J15-A, LANE NO-40, RAJAPURI,J15-UTTAM NAGAR,DELHI, 110059
                      <br />
                      Phone: 9866773092 | Email: pankaj623@gmail.com | Website:
                      https://www.adsum.co.in
                    </div>
                  </div>
                </div>
              
              </div>
              <div style={{ marginTop: 10 }}>&nbsp;</div> */}
            </div>
            {/* <!-- page 3 ends --> */}

            <div className="page-break" />
            <div
              className="content"
              style={{
                margin: "30px 0 0 0",
                padding: "0 10px",
                width: "100%",
                height: "auto",
                border: "2px solid #000",
              }}
            >
              {/*-  header */}
              <h4 style={{ marginTop: "5px" }}>
                <strong>
                  WHEELS / TYRES
                </strong>
              </h4>
              <table className="rowheight" width="100%" height="auto">
                <tbody>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Wheel Type</td>
                    <td width="30%">                    Wheel</td>
                    <td width="30%">---</td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Undercarriage Condition</td>
                    <td width="30%">{caseinfo.farmEquipment.undercarriageCondition}</td>
                    <td width="30%">
                      {
                        caseinfo.farmEquipment.undercarriageCondition === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.undercarriageCondition === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.undercarriageCondition === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.undercarriageCondition === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.undercarriageCondition === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Paint Condition</td>
                    <td width="30%">{caseinfo.farmEquipment.paintCondition}</td>
                    <td width="30%">
                      {
                        caseinfo.farmEquipment.paintCondition === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.paintCondition === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.paintCondition === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.paintCondition === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.paintCondition === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
              <h4 style={{ marginTop: "5px" }}>
                <strong>
                  ELECTRICALS:
                </strong>
              </h4>
              <table className="rowheight" width="100%" height="auto">
                <tbody>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Battery</td>
                    <td width="30%">{caseinfo.farmEquipment.battery}</td>
                    <td width="30%">
                      {
                        caseinfo.farmEquipment.battery === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.battery === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.battery === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.battery === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.battery === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Battery Numbers</td>
                    <td width="30%">{caseinfo.farmEquipment.batteryNo}</td>
                    <td width="30%">
                    </td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Starter Motor</td>
                    <td width="30%">{caseinfo.farmEquipment.starterMotor}</td>
                    <td width="30%">
                      {
                        caseinfo.farmEquipment.starterMotor === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.starterMotor === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.starterMotor === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.starterMotor === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.starterMotor === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Instrument Panel</td>
                    <td width="30%">{caseinfo.farmEquipment.instrumentPanel}</td>
                    <td width="30%">
                      {
                        caseinfo.farmEquipment.instrumentPanel === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.instrumentPanel === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.instrumentPanel === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.instrumentPanel === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.instrumentPanel === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">HeadLamp</td>
                    <td width="30%">{caseinfo.farmEquipment.headLamp}</td>
                    <td width="30%">
                      {
                        caseinfo.farmEquipment.headLamp === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.headLamp === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.headLamp === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.headLamp === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.headLamp === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">TailLamp</td>
                    <td width="30%">{caseinfo.farmEquipment.tailLamp}</td>
                    <td width="30%">
                      {
                        caseinfo.farmEquipment.tailLamp === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.tailLamp === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.tailLamp === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.tailLamp === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.tailLamp === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Work Lamps Numbers</td>
                    <td width="30%" >{caseinfo.farmEquipment.workLampNo}</td>
                    <td width="30%">
                    </td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Work Lamp Condition</td>
                    <td width="30%">{caseinfo.farmEquipment.workLampCondition}</td>
                    <td width="30%">
                      {
                        caseinfo.farmEquipment.workLampCondition === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.workLampCondition === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.workLampCondition === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.workLampCondition === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.workLampCondition === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Electricals Condition</td>
                    <td width="30%">{caseinfo.farmEquipment.electricalCondition}</td>
                    <td width="30%">
                      {
                        caseinfo.farmEquipment.electricalCondition === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.electricalCondition === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.electricalCondition === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.electricalCondition === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.electricalCondition === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Odoemeter Type</td>
                    <td width="30%">{caseinfo.farmEquipment.odometerType}</td>
                    <td width="30%">
                    </td>
                  </tr>
                </tbody>
              </table>
              <h4 style={{ marginTop: "5px" }}>
                <strong>
                  ATTCHMENTS / IMPLEMENTS:
                </strong>
              </h4>
              <table className="rowheight" width="100%" height="auto">
                <tbody>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">ANy Attachemnt/Implement?</td>
                    <td width="30%">{caseinfo.farmEquipment.attachment}</td>
                    <td width="30%">
                      {caseinfo.farmEquipment.attachment === "Yes" ? (
                        <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/right.jpg" />
                      ) : (
                        <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/wrong.jpg" />
                      )}
                    </td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Name of Attachemnt/Implement?</td>
                    <td width="30%">{caseinfo.farmEquipment.attachmentName}</td>
                    <td width="30%">
                    </td>
                  </tr>
                </tbody>
              </table>
              <h4 style={{ marginTop: "5px" }}>
                <strong>
                  ADDITIONAL FEATURE:
                </strong>
              </h4>
              <table className="rowheight" width="100%" height="auto">
                <tbody>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Air Compresspr</td>
                    <td width="30%">{caseinfo.farmEquipment.airCompressor}</td>
                    <td width="30%">
                      {caseinfo.farmEquipment.airCompressor === "Yes" ? (
                        <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/right.jpg" />
                      ) : (
                        <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/wrong.jpg" />
                      )}
                    </td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Toolkit</td>
                    <td width="30%">{caseinfo.farmEquipment.ToolKit}</td>
                    <td width="30%">
                      {caseinfo.farmEquipment.ToolKit === "Yes" ? (
                        <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/right.jpg" />
                      ) : (
                        <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/wrong.jpg" />
                      )}
                    </td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Lift</td>
                    <td width="30%">{caseinfo.farmEquipment.lift}</td>
                    <td width="30%">
                      {caseinfo.farmEquipment.lift === "Yes" ? (
                        <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/right.jpg" />
                      ) : (
                        <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/wrong.jpg" />
                      )}
                    </td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Tipper</td>
                    <td width="30%">{caseinfo.farmEquipment.lift}</td>
                    <td width="30%">
                      {caseinfo.farmEquipment.lift === "Yes" ? (
                        <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/right.jpg" />
                      ) : (
                        <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/wrong.jpg" />
                      )}
                    </td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Hitch</td>
                    <td width="30%">{caseinfo.farmEquipment.hitch}</td>
                    <td width="30%">
                      {caseinfo.farmEquipment.hitch === "Yes" ? (
                        <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/right.jpg" />
                      ) : (
                        <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/wrong.jpg" />
                      )}
                    </td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Power Steering</td>
                    <td width="30%">{caseinfo.farmEquipment.powerSteering}</td>
                    <td width="30%">
                      {caseinfo.farmEquipment.powerSteering === "Yes" ? (
                        <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/right.jpg" />
                      ) : (
                        <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/wrong.jpg" />
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              <h4 style={{ marginTop: "5px" }}>
                <strong>
                  ADDITIONAL DETAILS:
                </strong>
              </h4>
              <table className="rowheight" width="100%" height="auto">
                <tbody>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Whether Accidental</td>
                    <td width="30%">{caseinfo.farmEquipment.accidental}</td>
                    <td width="30%">
                      {caseinfo.farmEquipment.accidental === "Yes" ? (
                        <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/right.jpg" />
                      ) : (
                        <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/wrong.jpg" />
                      )}
                    </td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Level Of Maintenance</td>
                    <td width="30%">{caseinfo.farmEquipment.maintenanceLevel}</td>
                    <td width="30%">
                      {
                        caseinfo.farmEquipment.maintenanceLevel === 'Poor' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lpoor.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.maintenanceLevel === 'Average' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lave.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.maintenanceLevel === 'Satisfactory' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lsati.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.maintenanceLevel === 'Good' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lgood.png" /> : <></>
                      }
                      {
                        caseinfo.farmEquipment.maintenanceLevel === 'Excellent' ?
                          <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/lexcel.png" /> : <></>
                      }
                    </td>
                  </tr>
                  <tr style={{ height: "5px" }}>
                    <td width="35%">Other Repairs If Any</td>
                    <td width="30%">{caseinfo.farmEquipment.otherRepair}</td>
                    <td width="30%">
                      {caseinfo.farmEquipment.otherRepair === "Yes" ? (
                        <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/right.jpg" />
                      ) : (
                        <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/wrong.jpg" />
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* <!-- page 4 ends --> */}
            <div className="page-break" />
            <div style={{ marginTop: 10 }}>&nbsp;</div>
            <div
              style={{
                padding: "2px 25px",
                border: "1px solid #000",
                width: "100%",
                height: "auto"
              }}
            >
              <div className="row">
                <div className="col-sm-3 p-2">
                  <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/toplogo.png" />
                </div>
                <div className="col-sm-6 text-center">
                  <h4>
                    <strong>
                      Valuation Report
                      <p>
                        {caseinfo.assetType}
                      </p>
                      <p>
                        {brandSet.length > 20
                          ? brandSet.slice(0, 20) + "..." // Truncate the string if it's longer than 20 characters
                          : brandSet}
                      </p>
                    </strong>
                  </h4>
                  &nbsp;
                </div>
                <div className="col-sm-3 text-right">
                  <img src="/assets/Adsum Advisory Services Pvt. Ltd._files/sidelogo.png" />
                </div>
              </div>
            </div>
            {/*-  header */}

            {/*- content border */}
            <h4>
              <strong>
                IMAGES:
              </strong>
            </h4>
            <div className="row">
              <div className="col-sm-12 newReport">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
                  {
                    Object.keys(caseinfo.images).map((key) => {
                      if (key === 'selfieWithVehicle' && caseinfo.images[key] !== "") {
                        return (
                          <span key={key} style={{ width: '45%', margin: '10px' }}>
                            <a target="_blank" href={caseinfo.images.selfieWithVehicle}>
                              <div style={{ position: "relative" }}>
                                <img
                                  src={caseinfo.images.selfieWithVehicle}
                                  style={{ height: "250px", width: "100%" }}
                                  alt="Selfie with Vehicle"
                                />
                                <img
                                  src="/assets/Adsum Advisory Services Pvt. Ltd._files/sidelogo.png"
                                  className="small-logo"
                                  width="75px"
                                  height="53px"
                                  alt="Side Logo"
                                />
                              </div>
                            </a>
                          </span>
                        );
                      }
                      return null;
                    })
                  }
                  {
                    Object.keys(caseinfo.images).map((key) => {
                      if (key === 'frontImage' && caseinfo.images[key] !== "") {
                        return (
                          <span key={key} style={{ width: '45%', margin: '10px' }}>
                            <a key={key} target="_blank" href={caseinfo.images.frontImage}>
                              <div style={{ position: "relative" }}>
                                <img
                                  src={caseinfo.images.frontImage}
                                  style={{ height: "250px", width: "420px" }}
                                  alt="Front Image"
                                />
                                <img
                                  src="/assets/Adsum Advisory Services Pvt. Ltd._files/sidelogo.png"
                                  className="small-logo"
                                  width="75px"
                                  height="53px"
                                  alt="Side Logo"
                                />
                              </div>
                            </a>
                          </span>
                        );
                      }
                      return null;
                    })
                  }
                  {
                    Object.keys(caseinfo.images).map((key) => {
                      if (key !== 'selfieWithVehicle' && key !== 'frontImage' && caseinfo.images[key] !== "") {
                        return (
                          <span key={key} style={{ width: '45%', margin: '10px' }}>
                            <a key={key} target="_blank" href={caseinfo.images[key]}>
                              <div style={{ position: "relative" }}>
                                <img
                                  src={caseinfo.images[key]}
                                  style={{ height: "250px", width: "420px" }}
                                  alt="Other Images"
                                />
                                <img
                                  src="/assets/Adsum Advisory Services Pvt. Ltd._files/sidelogo.png"
                                  className="small-logo"
                                  width="75px"
                                  height="53px"
                                  alt="Side Logo"
                                />
                              </div>
                            </a>
                          </span>
                        );
                      }
                      return null;
                    })
                  }
                </div>
                <div className="row">
                  <div className="col-sm-12 newReport">
                    <div
                      style={{
                        padding: "15px 10px",
                        border: "1px solid #686868",
                      }}
                    >
                      Disclaimer:
                      <br />
                      o This report is issued without prejudice
                      <br />
                      o The valuation has been done based on the superficial
                      examination of vehicle condition and the doeuments
                      provided at the time of inspection.
                      <br />
                      o This report is valid for the date of inspection and time
                      only
                      <br />
                      o This valuation has been performed to the best of our
                      ability and our responsibility is limited to proven
                      negligence.
                      <br />
                      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', paddingRight: '20px' }}>
                        <img
                          src="/assets/stamp.jpeg"
                          width="80px"
                          height="80px"
                        />
                      </div>
                      <div className="text-right">
                        <p>
                          Authorized Signatory
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: 3 }}>&nbsp;</div>
                <div className="row">
                  <div className="col-sm-12 newReport">
                    <div
                      style={{
                        padding: 2,
                        border: "1px solid #000",
                        width: "100%",
                      }}
                    >
                      {/* page footer */}
                      <div className="col-sm-12 newReport text-center">
                        <strong>Head Office:</strong>
                        <br />
                        Door No. 411,4th Floor, Eden Amsri Square,beside Apollo
                        Hospital, Sangeet Circle,
                        <br />
                        Secunderabad 500003, Telengana
                      </div>
                    </div>
                  </div>
                  {/* page footer ends */}
                </div>
              </div>
            </div>
            <br />
            {/* /div> <!-- A4 size */}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default FarmEquipmentReport;
